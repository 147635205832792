<template>
  <v-container v-if="vueAddEvento">
    <PageToolbar
      :title="$tc('eventos.addevento')"
      icon="date_range"
      dark
      voltar
    />

    <v-card class="mt-6 pa-6">
      <v-alert v-if="eventoIgual.length" type="error" prominent>
        <v-row align="center">
          <v-col class="grow">
            {{ $tc("eventos.jaexiste") }}
          </v-col>
          <v-col class="shrink">
            <v-btn
              v-if="eventoIgual[0].id"
              :to="{ path: `/eventos/${eventoIgual[0].id}` }"
              color="secondary"
            >
              {{ $tc("eventos.verevento") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-row v-if="!loading">
        <!-- Cliente -->
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedCliente"
            :items="clientes"
            :loading="loadingCliente"
            :search-input.sync="search"
            @click:clear="clearSearch"
            @keyup="buscaClientes"
            clearable
            dense
            filled
            hide-details
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            :label="$tc('pages.cliente')"
            :placeholder="$tc('msg.digitarbuscar')"
            prepend-inner-icon="search"
            return-object
          ></v-autocomplete>
        </v-col>

        <!-- adm_id -->
        <v-col cols="12" md="3">
          <v-text-field
            v-model="adm_id"
            label="Adm ID"
            dense
            filled
            hide-details
            :placeholder="!evento.cliente_id ? 'cliente primeiro' : ''"
            :error="!evento.cliente_id ? false : adm_id ? false : true"
            :readonly="
              selectedCliente ? (selectedCliente.adm_id ? true : false) : false
            "
            :disabled="!evento.cliente_id ? true : false"
            @blur="buscaAdmCliente"
            :loading="buscandoAdmCliente"
          ></v-text-field>
        </v-col>

        <!-- Login Radius -->
        <v-col cols="12" md="3">
          <v-text-field
            v-model="login_radius"
            label="Login Radius"
            dense
            filled
            hide-details
            readonly
            :placeholder="
              !evento.cliente_id
                ? 'cliente primeiro'
                : !adm_id
                ? 'adm_id primeiro'
                : ''
            "
            :disabled="!evento.cliente_id ? true : adm_id ? false : true"
          ></v-text-field>
        </v-col>

        <!-- Origen -->
        <v-col cols="12" md="3">
          <v-select
            v-model="evento.origem_id"
            :items="origens"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('pages.origem')"
          ></v-select>
        </v-col>

        <!-- Classificações -->
        <v-col cols="12" md="3">
          <v-select
            v-model="evento.classificacao_id"
            :items="classificacoes"
            @change="loadTipos"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('pages.classificacao', 2)"
            :placeholder="!evento.cliente_id ? 'cliente primeiro' : ''"
            :disabled="!evento.cliente_id ? true : false"
          ></v-select>
        </v-col>

        <!-- Evento Tipo -->
        <v-col cols="12" md="3">
          <v-combobox
            v-model="selectedTipo"
            :items="filteredEventoTipos"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('audits.tipoevento', 2)"
            :placeholder="
              !evento.classificacao_id ? 'classificacão primeiro' : ''
            "
            :disabled="!evento.classificacao_id ? true : false"
          ></v-combobox>
        </v-col>

        <!-- Prioridade -->
        <v-col cols="12" md="3">
          <v-select
            v-model="evento.prio"
            :items="prioridades"
            clearable
            item-text="text"
            item-value="value"
            dense
            filled
            hide-details
            :label="$tc('global.prioridade')"
            :placeholder="!selectedTipo ? 'tipo primeiro' : ''"
            :disabled="!selectedTipo ? true : false"
          ></v-select>
        </v-col>

        <!-- Localização -->
        <v-col cols="12" md="3">
          <v-text-field
            v-model="evento.localizacao"
            :label="$tc('global.localizacao')"
            dense
            filled
            hide-details
            :placeholder="!evento.cliente_id ? 'cliente primeiro' : ''"
            :disabled="!evento.cliente_id ? true : false"
          ></v-text-field>
        </v-col>

        <!-- Descricao -->
        <v-col cols="12" md="9">
          <v-text-field
            v-model="evento.descricao"
            :placeholder="$tc('msg.escrevadescricao')"
            :label="$tc('global.descricao')"
            class="pa-0 ma-0"
            :counter="255"
            :rules="rules"
            filled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-text v-else>
        <Carregando />
      </v-card-text>
    </v-card>

    <v-btn
      @click="createEvento"
      :disabled="valid"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { postEvento } from "@/services/api/eventos.api.js";
import {
  getListClientes,
  getClienteAdm,
  putCliente,
} from "@/services/api/clientes.api.js";
import { getContas } from "@/services/api/contasreceber.api.js";
import { getUltConexao } from "@/services/api/conexoes.api.js";
import { getEventos } from "@/services/api/eventos.api.js";
import { mapState, mapActions, mapGetters } from "vuex";

import { format, parseISO, formatDistance } from "date-fns";

import ptBrLocale from "date-fns/locale/pt-BR";

export default {
  name: "NovoEvento",

  data() {
    return {
      componentKey: 0,
      evento: {
        descricao: "",
        tipo_evento_id: null,
        prio: null,
        cliente_id: null,
        classificacao_id: null,
        origem_id: null,
      },
      eventoIgual: [],
      time: null,
      menu2: false,
      filteredEventoTipos: [],
      loading: false,
      clientes: [],
      selectedCliente: {},
      selectedTipo: null,
      loadingCliente: false,
      agendarPara: false,
      search: "",
      rules: [(v) => v.length <= 255 || "Maximo de 255 caracteres"],
      adm_id: null,
      login_radius: null,
      buscandoAdmCliente: false,
      contas: [],
      radacct: null,
      checkingCliente: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueAddEvento() {
      return this.getAccess("vueEvento", "adicionar");
    },
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
    }),
    ...mapState("Eventos", {
      classificacoes: (state) => state.classificacoes,
      eventoTipos: (state) => state.eventoTipos,
      origens: (state) => state.origens,
      prioridades: (state) => state.prioridades,
    }),
    valid() {
      if (
        this.evento.descricao &&
        this.evento.descricao.length <= 255 &&
        this.evento.tipo_evento_id != null &&
        this.evento.prio != null &&
        this.evento.cliente_id != null &&
        this.evento.classificacao_id != null &&
        this.evento.origem_id != null
      ) {
        return false;
      } else {
        return true;
      }
    },
    headerContas() {
      return [
        {
          text: "historico",
          value: "historico",
        },
        {
          text: "vencimento",
          value: "data_vencimento",
        },
        {
          text: "pagamento",
          value: "data_pagamento",
        },
        // {
        //   text: "valor",
        //   align: "right",
        //   value: "valor",
        // },
        // {
        //   text: "valor pago",
        //   align: "right",
        //   value: "valor_pago",
        // },
        // {
        //   text: "desconto",
        //   align: "right",
        //   value: "desconto",
        // },
        {
          text: "saldo",
          align: "right",
          value: "saldo",
        },
      ];
    },
  },

  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        this.evento.cliente_id = this.selectedCliente.id;
        if (this.selectedCliente.localizacao) {
          this.evento.localizacao = this.selectedCliente.localizacao;
        }
        this.adm_id = this.selectedCliente.adm_id;
        this.login_radius = this.selectedCliente.login_radius;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.evento.tipo_evento_id = this.selectedTipo.id;
        this.evento.prio = this.selectedTipo.prioridade;

        this.checkEventoIgual(
          this.evento.cliente_id,
          this.evento.tipo_evento_id
        );
      }
    },
  },

  methods: {
    row_classes(item) {
      if (Number(item.saldo) > 0) {
        return ["error--text", "font-weight-bold"]; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    conectadoDiff(campo) {
      if (this.radacct) {
        return formatDistance(new Date(), parseISO(campo), {
          locale: ptBrLocale,
        });
      } else {
        return "";
      }
    },
    buscaClientes(event) {
      if (this.search) {
        if (this.search.length > 3 && event.key != "Backspace") {
          this.getClientes(`?search=${this.search}`);
        }
      }
    },
    clearSearch() {
      this.evento.cliente_id = null;
      this.evento.localizacao = "";
      this.adm_id = null;
      this.login_radius = null;
    },
    loadTipos(id) {
      let filter = this.eventoTipos.filter(
        (item) => item.classificacao_id === id
      );
      this.filteredEventoTipos = filter;
    },
    getClientes(filtros) {
      this.loadingCliente = true;
      this.clientes = [];

      getListClientes(filtros)
        .then((response) => {
          this.clientes = response;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingCliente = false;
        });
    },
    ...mapActions("Eventos", {
      getClassificacoes: "getClassificacoes",
      getEventosTipos: "getEventosTipos",
      getOrigens: "getOrigens",
    }),
    clearAfterCreate() {
      this.selectedCliente = {};
      this.selectedTipo = null;
      this.evento = {};
      this.evento.descricao = "";
    },
    checkEventoIgual(cliente_id, tipo) {
      let data_inicio = format(new Date(), "yyyy-MM-dd"),
        data_fim = data_inicio + " 23:59:59",
        query = `?cliente=${cliente_id}&tipo=${tipo}&dataFrom=${data_inicio}&dataTo=${data_fim}`;
      getEventos(query)
        .then((response) => {
          this.eventoIgual = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buscaAdmCliente() {
      if (this.adm_id && !this.selectedCliente.adm_id) {
        this.buscandoAdmCliente = true;
        getClienteAdm(this.adm_id)
          .then((response) => {
            this.login_radius = response.pppoe;

            if (this.selectedCliente.nome != response.nome) {
              this.$toast.error("Nome de cliente nao bate com ADM");
            }

            this.buscandoAdmCliente = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("Adm id incorreto ou nao encontrado!");
            }
            this.buscandoAdmCliente = false;
          });
      }
    },

    async createEvento() {
      this.loading = true;
      this.evento.user_id = this.user_id;
      this.evento.status = 0;
      this.evento.clienteName = this.selectedCliente.nome;
      this.evento.responsable = false;
      this.evento.criador = true;

      this.$Progress.start();
      await postEvento(this.evento)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Evento criado com sucesso!");
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$Progress.fail();
        });

      if (
        (!this.selectedCliente.localizacao && this.evento.localizacao) ||
        (!this.selectedCliente.adm_id && this.adm_id) ||
        (!this.selectedCliente.login_radius && this.login_radius)
      ) {
        this.selectedCliente.localizacao = this.evento.localizacao;
        this.selectedCliente.adm_id = this.adm_id;
        this.selectedCliente.login_radius = this.login_radius;
        await putCliente(this.selectedCliente.id, this.selectedCliente)
          .then(() => {
            this.$toast.success("Cliente atualizado com sucesso!");
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
          });
      }

      this.clearAfterCreate();
      this.loading = false;
      this.$Progress.finish();
    },
  },
  async mounted() {
    if (this.vueAddEvento) {
      this.loading = true;
      if (!this.classificacoes.length) {
        await this.getClassificacoes();
      }
      if (!this.eventoTipos.length) {
        await this.getEventosTipos();
      }
      if (!this.origens.length) {
        await this.getOrigens();
      }
      this.loading = false;
    }
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .headline {
  input {
    font-size: 1.4rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
  }
}
</style>
